import { DefaultSeoProps } from 'next-seo'
import { FeatureFlag, featureFlagEnabled } from './feature_flags'

const DEFAULT_SEO: DefaultSeoProps = {
  title: 'Invest in the world’s rarest fine art',
  description:
    'Access exclusive investments backed by blue chip masterworks. Buy, sell and collect with our global community. First collection coming 2023',
  dangerouslySetAllPagesToNoFollow: featureFlagEnabled(FeatureFlag.TestNet),
  dangerouslySetAllPagesToNoIndex: featureFlagEnabled(FeatureFlag.TestNet),
  openGraph: {
    type: 'website',
    url: 'https://freeport.app',
    title: 'Freeport',
    description: 'Invest in the world’s rarest fine art',
    images: [
      {
        url: 'https://freeport.app/images/marketing/og-the-future-of-fine-art-collecting.png',
        width: 1200,
        height: 630,
        alt: 'Freeport',
      },
    ],
  },
}

export { DEFAULT_SEO }
