const TESTNET_ENABLED: string =
  process.env.TESTNET || process.env.NEXT_PUBLIC_TESTNET || 'false'

enum FeatureFlag {
  TestNet,
}

const featureFlagEnabled = (flag: FeatureFlag): boolean => {
  switch (flag) {
    case FeatureFlag.TestNet:
      return TESTNET_ENABLED.toLowerCase() === 'true' || false
    default:
      return false
  }
}

export { featureFlagEnabled, FeatureFlag }
