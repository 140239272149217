import { goerli } from 'wagmi/chains'
import { createClient, configureChains } from 'wagmi'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const { chains, provider, webSocketProvider } = configureChains(
  [goerli], // Using Goerli for testnet experience
  [infuraProvider({ apiKey: process.env.INFURA_API_KEY }), publicProvider()]
)
export const generateClient = () =>
  createClient({
    autoConnect: true,
    connectors: [
      new InjectedConnector({ chains }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
        },
      }),
    ],
    provider,
    webSocketProvider,
  })

export const wagmiClient = generateClient()
